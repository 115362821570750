<template>
  
  <div>

    <UiBread v-if="vote_data"
        :list="[
          {name: $t('journal_view_link_text'), link: '/journal'},
          {name: $t('journal_view_link_voting'), link: '/journal/voting'},
          {name: vote_data.title},
        ]"
        position="left"
        /> 

    <div class="journal page_journal_item">

      <JournalVoteBox v-if="vote_data"
        :data="vote_data"      
        />

      <br/>
      <br/>

      <CommentWidget v-if="vote_data && vote_data.id"
          :title="$t('journal_view_post_comments')"
          :type="'blog'"
          :content="vote_data.id"
          />

      <div class=""> 
      
        <div class="sdbr"> 

          <JournalVacancy v-if="vacancy && vacancy.length"
            :data="vacancy"      
            />

          <JournalTagItems v-if="tags && tags.length"
            :data="tags"      
            />

        </div>
      </div>
    
    </div>
  
  </div>

</template>

<script setup>

const route = useRoute();
const {$api, $ga} = useNuxtApp()
var id = ref(route.params['id']);

const loadData = async function() {     
  const response = await $api.getJournalVote(route.params['var'])
  return response;  
}


const { pending: is_loading,  data: vote_data } = await useLazyAsyncData('vote_data', async () => await loadData())

 
useHead(useNuxtApp().$head.getJournalVote(vote_data.value));

</script>

<style scoped>

.journal {
    padding: 0 2rem;
    margin: 2rem 0;
}
 

@media (max-width: 768px) {
 
  .journal_page{
    padding: 0 2rem;
    margin: 2rem 0;
  }

}


 

.page_journal_item .bd{
  display: flex;
  flex-wrap: wrap; 
}
.page_journal_item .bd .lst{
  width: calc(100% - 2rem - 17rem);
  margin-right: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}  




/*  */
/*  */
/*  */
/*  */

 
.page_journal_item{
  margin-bottom: 25px; 
  padding-bottom: 22px;
}
.page_journal_item .blog_item{
  width: 100%;
}
.page_journal_item .ddate{
    margin-bottom: 10px;
    font-size: 0.71428571rem;

    line-height: 1;
    vertical-align: baseline;
    background-image: none;
    color: rgba(0, 0, 0, 0.6);
    text-transform: none;
    font-weight: bold;
}
.page_journal_item .label_new{
  display: inline-block;
  background-color: #68ae3c;
  color: white;
  margin-right: 7px;
  vertical-align: middle;
  margin-top: -4px;
}
.page_journal_item .title{    
    margin-top: 2rem;
}
.page_journal_item .title h1{
  font-size: 2.8rem!important;
  line-height: 3.4rem;
  font-family: 'Poppins', 'Work Sans', sans-serif;
  color: black!important;
}
.page_journal_item .title a{
    color: #494949;
    display: inline-block;
}
.page_journal_item .body{
  display: block;
}
.page_journal_item .body:hover{
  color: inherit;
}
.page_journal_item .body h2{
  font-size: 2.1rem!important;
  line-height: 2.3rem;
  font-family: 'Poppins';
  font-weight: 500;
  color: black!important;
  margin-top: 2.5rem;
}

.page_journal_item .body h3{
  font-size: 1.9rem!important;
  line-height: 2rem;
  font-family: 'Poppins';
  font-weight: 500;
  color: black!important;
  margin-top: 2rem;
}
.page_journal_item .share_box, 
.page_journal_item .tags{
    margin-bottom: 10px;
 
}  
.page_journal_item .tags{
  font-weight: 500;
}  
.page_journal_item .body{
    margin-bottom: 10px;
    font-size: 1.4rem;
} 
.page_journal_item .body > p{
  line-height: 2.1rem;
  color: black;
}
.page_journal_item .body li{  
  margin-bottom: 0.5rem;
}
.page_journal_item .body li > span{  
  color: black;
  line-height: 2.1rem;
}
.page_journal_item .body b, 
.page_journal_item .body strong {
  font-weight: 500!important;
}
.page_journal_item .body img{
    max-width: 100%;
    height: auto;
    margin-left: calc(100%/2 * -1 + 100%/2);
}
.page_journal_item .body iframe{
    max-width: 100%;
}
.page_journal_item .body *:not(.embed) a,
.page_journal_item .body a:not(.report_item){
  color: #5bb523;
  font-weight: 500;
  text-decoration: underline;
}
.page_journal_item .body h1,
.page_journal_item .body h2,
.page_journal_item .body h4,
.page_journal_item .body h5,
.page_journal_item .body b{
    /* font-weight: bold; */
}
@media (max-width: 768px) {
  .page_journal_item .body li > span,
  .page_journal_item .body > p{
    line-height: 1.8rem;
  }
}
/* COMMENT */

#comments_post{
    max-width: 100%;
}
#comments_post .select_user_comm{

}
#comments_post .select_user_comm img{
        width: auto;
    height: auto;
    margin: 0;
    display: inline-block;
    margin-right: 5px;
}
#comments_post .select_user_comm .text{
    vertical-align: middle;
}
#comments_post .replies_loader{
    margin-left: 30px;
    color: #4183C4;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 5px;
    cursor: pointer;
}
#comments_post .replies .comment:last-child{
        border-bottom: 1px #dddede solid;
    padding-bottom: 12px;
}
#comments_post .box_form_reply{
    margin-left: 3.5em;
}
 
.settings_comments{
  margin-top: 1rem;
}


.page_journal_item .other{
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-left: -2px;
}




@media (max-width: 768px) {
 
  .journal_page{
    padding: 0 2rem;
    margin: 2rem 0;
  }
  .page_journal_item .body{ 
    font-size: 1.2rem;
  }
  .page_journal_item .title h1 {
    font-size: 1.8rem!important;
    line-height: 2rem;
  }
  .page_journal_item .body h2{
    
    font-size: 1.5rem!important;
    line-height: 1.8rem;
  }
  .page_journal_item .body img {
    max-width: calc(100% + 4rem);
    height: auto;
    margin-left: -2rem;
  }

  .page_journal_item .other{
  flex-wrap: wrap;
  }
}


/* CAPTIONS */
 
.imgcap {
  color: #909090;
  font-style: italic;
  margin-top: -27px;
  border-top: 1px #d0d0d0 solid;
  font-size: 1.2rem;
}
@media (max-width: 768px) {
  
.imgcap {
  margin-top: -25px;
  font-size: 1.1rem;
}
}


/* SHARE */

.share{ 
  margin-right: auto; 
}
@media (max-width: 768px) {

  .share{ 
    margin-bottom: 1rem;
  }
}

/* RELATED */
.related{
  /* background-color: #f7f7f7; */
  border-radius: 5px;
  /* padding: 1.5rem 2rem; */
  /* border-top: 2px #7d7d7d solid; */
  margin-top: 2rem;
  margin-bottom: 0rem;
}
.related .rtitle{
  /* font-size: 1.4rem; */
  /* text-transform: uppercase; */
  margin-bottom: 1rem;
  /* color: #ababab; */
  font-size: 1.28rem;
}
.related .rlist{
  
}
.related .rlist .ritem{
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.related .rlist .ritem .rimg{
  width: 100px;
  height: 60px;
  margin-right: 2rem;
}
.related .rlist .ritem .rimg img{
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 5px;
}
.related .rrelate{
  display: block;
  color: black;
  /* font-weight: 500; */
  line-height: 1.6rem;
  font-size: 1.4rem;
  /* text-decoration: underline; */
}
.related .rrelate span{
  border-bottom: 1px #68ae3c solid;
  line-height: 1.8rem;
}
.related .rrelate:not(:last-child){
  border-bottom: 1px #dededf solid;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
 .related{
   border-radius: 0;
   /* width: calc(100% + 4rem); */
   /* margin-left: -2rem; */
 }
 .related .rrelate{ 
  line-height: 1.4rem;
  font-size: 1.2rem;
 }
}
/*  */




/* COMMENTS */


.reviews_list{  
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Noto Sans,Ubuntu,Droid Sans,Helvetica Neue,sans-serif;
  font-size: 1.2rem!important;
}

.reviews_list .add_comment_buttons .button{    
  font-size: 1.2rem!important;
}
.reviews_list .date{
  font-weight: normal!important;
}

.reviews_list .comment_area{
  font-size: 1.2rem!important;
}

.reviews_list .author{
  font-size: 1.2rem!important;
}
.reviews_list .text{
  font-size: 1.2rem!important;
}
.reviews_list .comments_post{

}

@media (max-width: 768px) {

  .reviews_list{
    margin-left: -2rem;
    width: calc(100% + 4rem);
  }
  .reviews_list .comments_post{
    margin-left: 2rem;
    margin: 2rem;
  }
  .reviews_list .author{
    font-size: 1.2rem!important;
  }
  .reviews_list .text{
    font-size: 1.2rem!important;
  }
  .settings_comments{
    margin: 0 2rem;
    margin-top: 1rem;
  }

}








/* TABLES */

.blog_item .body table{
  /* display: block; */
  overflow: auto;
  max-width: 100%!important;
  /* border: 0px !important; */
  background-color: transparent!important;
  height: auto!important;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid rgba(34,36,38,.15);
} 
.blog_item .body table td{
  padding: 0.6rem 1rem;
  border-top: 0;
  border-left: 1px solid rgba(34,36,38,.1);
  border-top: 1px solid rgba(34,36,38,.1);
}
.blog_item .body table tr:nth-child(even):last-child td{ 
  border-bottom: 1px #eeeeee solid!important;
}
.blog_item .body table thead td{
  font-weight:500;
}
.blog_item .body table tbody tr:nth-child(even){
  background-color: #eeeeee0a;
}
.blog_item .body table tbody tr:nth-child(odd){
  background-color:#eeeeee8f;
}
.blog_item .body table td ul{
  padding-left: 18px;
  margin-left: 0;
  line-height: 1.4rem;
  margin: 0;
}
.blog_item .body table td ul ul{
  padding-left: 0px;}
/*  */

/* AUTHOR DATE */
.blog_item .ud{
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
 

.blog_item .author{
    display: flex;
    align-items: center;
}
.blog_item .author .by{
  margin-right: 0.5rem;
}
.blog_item .author .nm{}
.blog_item .author .av{
    width: 23px;
    margin-right: 0.5rem;
    height: 23px;
}
.blog_item .author .av img{
    width: 100%;
    border-radius: 100px;
}
 

.blog_item .published{
  margin-left: auto;
}



/* CONTENT */


.blog_item .body .content_box{
  border-top: 1px #e4e4e4 solid;
  padding-top: 1rem;
}
.blog_item .body .content_text{
  list-style-type:none;
  padding-left: 1rem;
  margin-top: 0;
}
.blog_item .body .content_text li{
    margin: 7px 0;
    display: flex;
}
.blog_item .body .content_text li .num{
  opacity: 0.5;
  flex-shrink: 0;
  margin-right: 5px;
}
.blog_item .body .content_text li .num .bll{
  min-width:18px;
  display:inline-block;
}
.blog_item .body .content_text li a{
  color: #000000;
  text-decoration:underline;
  flex-shrink: 1;
  width: auto;
  overflow: hidden;
  white-space: normal;
  cursor: pointer;
  font-weight: normal;
  text-decoration: none;
}

/* SPONSORED */

.blog_item .pr_label{
    color: #6d6001;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.95rem;
    margin-bottom: -2rem;
    margin-top: 2rem;
    background-color: #dec400;
    padding: 3px 10px 1px 10px;
    width: fit-content;
    border-radius: 5px;
}

@media (max-width: 768px) {
  .pr_label{
    margin-bottom: -1rem;
  }
}

/* POLL */

.drag_border{
  background-color: #f2f2f2;
  border-radius: 5px;
}

.drag_num{
  position: relative;
  left: -10px;
}

.page_journal_item .blog_item label{    
  font-weight: normal!important;
  font-size: 1.4rem!important;
}

/*  */





@media (min-width: 768px) {


  .journal{
    margin: 3rem;
  } 
  .journal .small_boxes{
    margin-bottom: 1rem;
  }  
  .journal .votes{margin-bottom: 3rem;}
  .journal .vacancy{
    margin-bottom: 3rem;
  } 
  .journal .ptl{
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
    font-weight: normal;
  }

  
  .journal .ptla{
  text-transform: initial;
  color: darkgray;
  text-decoration: underline;
  font-size: 1.2rem;
  margin-left: 7px;
  display: inline-block;
  cursor: pointer;
  }
  
  
  
  .journal {
    display: grid;
    grid-template-columns: calc(100% - 300px - 3rem) 300px;
    grid-template-rows: auto auto auto;
    grid-gap: 3rem;
    grid-template-areas:
      "categories categories"
      "tops tops"
      "posts sdbr";
  }

  .journal.journal_list{
    
    display: grid;
    grid-template-columns: calc(100% - 300px - 3rem) 300px;
    grid-template-rows: auto;
    grid-gap: 3rem;
    grid-template-areas: 
      "posts sdbr";
  }
  
  .categories { grid-area: categories; }
  
  .tops { grid-area: tops; }
  
  .posts { grid-area: posts; }
  
  .sdbr { grid-area: sdbr; }
   
  
  /* LATEST */
  
  .journal .small_boxes{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
   
  /*  */
  
  /* POPULAR */
  
  .journal .middle_boxes{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 3rem;
  }
  
  .journal .middle_boxes .jbox{
    width: calc(100%/3 - 1rem);
    height: 250px;
  }
  
  /*  */
   
  
  
  
  @media (min-width: 1200px) and (max-width: 1400px) {
      
    .journal .small_boxes .jbox{
        width: calc(100%/2 - 1rem);
    } 
      
    .journal .middle_boxes .jbox{
        width: calc(100%/2 - 1rem);
    } 
    .journal .middle_boxes .jbox:nth-child(3){
        display: none;
    }
  
  }
  
  
  
  @media (min-width: 768px) and (max-width: 1200px) {
      
    .journal .small_boxes .jbox{
        width: calc(100%/1 - 1rem);
    } 
      
    .journal .middle_boxes .jbox{
        width: calc(100%/1 - 1rem);
    } 
    .journal .middle_boxes .jbox:nth-child(2),
    .journal .middle_boxes .jbox:nth-child(3){
        display: none;
    }
  
  }
  
  
  
  /* TOP */
  
  
  .tops {
    display: grid;
    grid-template-columns: 50% calc(25% - 1.5rem) calc(25% - 1.5rem);
    grid-template-rows: 250px 250px;
    grid-template-areas:
      "top_box_1 top_box_2 top_box_3"
      "top_box_1 top_box_4 top_box_5";
    grid-gap: 1.5rem;
  }
  
  .top_box_1 { grid-area: top_box_1; }
  
  .top_box_2 { grid-area: top_box_2; }
  
  .top_box_3 { grid-area: top_box_3; }
  
  .top_box_4 { grid-area: top_box_4; }
  
  .top_box_5 { grid-area: top_box_5; }
  
  /*  */
  
  
  }

</style>